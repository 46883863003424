import React from 'react'
import { Link } from 'gatsby'
import Prism from 'prismjs'

import Layout from '../../../components/layout'
import Seo from '../../../components/seo'
import MatrixInput from '../../../components/matrix-input'
import * as styles from './lib/eigen-tool.module.css'
import * as postStyles from '../../../styles/post.module.css'

import EigenWork from "./lib/eigen-work"
import EigenVector from "./lib/eigen-vectors"
import EigenValues from "./lib/eigen-values"

const calcCode = `
const scope = { a, b, c, d }
let vec1 = []
let vec2 = []

if (c === 0) {
  vec1 = [1, 0]

  if (a !== d) {
    const xtex = nerdamer('b/(d-a)', scope).toTeX()

    if (a < d) {
      vec1 = [xtex, 1]
      vec2 = [1, 0]
    } else if (a > d) {
      vec1 = [1, 0]
      vec2 = [xtex, 1]
    }
  }
} else if (c !== 0) {
  const x1tex = nerdamer('((a-d)+sqrt((a-d)^2+4*b*c))/(2c)', scope).toTeX()
  const x2tex = nerdamer('((a-d)-sqrt((a-d)^2+4*b*c))/(2c)', scope).toTeX()

  vec1 = [x1tex, 1]
  vec2 = [x2tex, 1]
}
`

const EigenTool = props => {
  const [a, seta] = React.useState(0)
  const [b, setb] = React.useState(0)
  const [c, setc] = React.useState(0)
  const [d, setd] = React.useState(0)
  const [wolframLink, setWolframLink] = React.useState('')
  let params = { a, c, b, d }

  const handleMatrixEdit = m => {
    seta(m[0])
    setc(m[1])
    setb(m[2])
    setd(m[3])
    params = { a, c, b, d }
  }

  React.useEffect(() => {
    setWolframLink(`https://www.wolframalpha.com/input/?i=%7B%7B${a}%2C${b}%7D%2C%7B${c}%2C${d}%7D%7D`)
  }, [a, c, b, d])

  React.useEffect(() => {
    window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub])
    Prism.highlightAll()
  }, [wolframLink])

  return (
    <Layout>
      <div className={postStyles.Post}>
        <section className="Header">
          <h1>$2\times2$ Matrix Eigenvector Tool</h1>
        </section>

        <section className="Section">
          <MatrixInput onChange={handleMatrixEdit} params={params}/>
          <div className={styles.wolfram}>
            Check with <a target="_blank" href={wolframLink} rel="noopener noreferrer" children="WolframAlpha"/>. Link is updated for ${'A'}$.
          </div>
          <div className="align-center">Based on the post <Link to="/math/eigenvector" children={"$2 \\! \\times \\! 2$ Matrix Eigenvector Formulae"} /></div>
        </section>

        <h2>Eigenvalues</h2>
        <section className="Section">
          <EigenValues {...params} />
        </section>

        <h2>Eigenvectors</h2>
        <section className="Section">
          <EigenVector {...params} />
          <hr/>
          <EigenWork {...params} />
        </section>

        <div className="align-center">
          <em>Powered by</em> <a href="https://nerdamer.com">Nerdamer</a>
        </div>
      </div>
    </Layout>
  )
}

export const Head = () => <Seo title="2x2 Matrix Eigenvector Tool" />

export default EigenTool