import * as React from "react"
import nerdamer from 'nerdamer'

import * as styles from './eigen-tool.module.css'

const EigenValues = props => {
  const { a, c, b, d } = props
  const [lambdaEqn, setLambdaEqn] = React.useState('')
  const [lambdas, setLambdas] = React.useState('')

  React.useEffect(() => {
    setLambdaEqn('$$'+eigenvalueEquation(a, c, b, d)+'$$')
    // setLambdas(solveLambdas(a, c, b, d))
    // if (c === 0 || b === 0) {
    //   if (a === d) {
    //     setLambdas(`$$ \\lambda_1 = ${a} $$`)
    //   } else {
    //     setLambdas(`$$
    //       \\begin{align}
    //         \\lambda_1 & = ${a} \\\\[5pt]
    //         \\lambda_2 & = ${d}
    //       \\end{align}
    //     $$`)
    //   }
    // } else {
    //   const l = solveLambdas(a, c, b, d)
    //   setLambdas(`$$
    //     \\begin{align}
    //       \\lambda_1 & = ${l[0]} \\\\[5pt]
    //       \\lambda_2 & = ${l[1]}
    //     \\end{align}
    //   $$`)
    // }
    const l = solveLambdas(a, c, b, d)
    if (JSON.stringify(l[0]) === JSON.stringify(l[1])) {
      setLambdas(`$$ \\lambda_1 = ${l[0]} $$`)
    } else {
      setLambdas(`$$
        \\begin{aligned}
          \\lambda_1 &= ${l[0]}\\\\[1em]
          \\lambda_2 &= ${l[1]}
        \\end{aligned}
      $$`)
    }
  }, [a, c, b, d])

  return (
    <>
      <div class="grid">
        <div class="grid-row">
          <div class="grid-label">Characteristic polynomial</div>
          <div class="grid-content">
            <div className={styles.eqn} children={lambdaEqn} />
          </div>
        </div>
        <div class="grid-row">
          <div class="grid-label">Eigenvalues</div>
          <div class="grid-content">
            <div children={lambdas} />
          </div>
        </div>
      </div>
    </>
  )
}

function eigenvalueEquation(a, c, b, d) {
  var str = 'p(\\lambda) = \\lambda^2'

  const z = -(a + d)
  if (z !== 0) {
    if (z > 0) {
      str += '+'
    } else if (z < 0) {
      str += '-'
    }
    if (Math.abs(z) !== 1) {
      str += Math.abs(z)
    }
    str += '\\lambda'
  }

  const y = -(c*b - a*d)
  if (y !== 0) {
    if (y > 0) {
      str += '+'
    }
    str += y
  }

  return str
}

function solveLambdas(a, c, b, d) {
  const x = []
  const scope = { b: -(a+d), c: -(c*b - a*d) }
  x[0] = nerdamer('(-b+sqrt(b^2-4c))/2', scope).toTeX()
  x[1] = nerdamer('(-b-sqrt(b^2-4c))/2', scope).toTeX()
  return x
}

export default EigenValues