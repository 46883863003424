// extracted by mini-css-extract-plugin
export var TextButton = "eigen-tool-module--TextButton--f823b";
export var WorkBox = "eigen-tool-module--WorkBox--8dfd0";
export var content = "eigen-tool-module--content--7cadf";
export var credit = "eigen-tool-module--credit--fc16b";
export var eqn = "eigen-tool-module--eqn--b321b";
export var hr = "eigen-tool-module--hr--8cff7";
export var matrix = "eigen-tool-module--matrix--9f835";
export var section = "eigen-tool-module--section--eb5ef";
export var vectors = "eigen-tool-module--vectors--7ba4e";
export var wolfram = "eigen-tool-module--wolfram--3923d";