import * as React from "react"
import nerdamer from 'nerdamer'

import * as styles from './eigen-tool.module.css'

const EigenWork = props => {
  const { a, c, b, d } = props
  const [workstr1, setWorkstr1] = React.useState('')
  const [workstr2, setWorkstr2] = React.useState('')
  // const [eqnstr, setEqnStr] = React.useState(`$$ ${c}x^2 - ${a - d}x - ${b} = 0 $$`)
  const [matrixtex, setMatrixTex] = React.useState(`$$ \\begin{pmatrix} ${a} & ${b} \\\\ ${c} & ${d} \\end{pmatrix} $$`)
  const [showWork, setShowWork] = React.useState(true)

  React.useEffect(() => {
    const scope = { p: a, q: c, r: b, s: d }
    const scope1 = {a,b,c,d}
    let str1 = ''
    let str2 = ''
    const top1TeX = nerdamer('((a-d)+sqrt((a-d)^2+4*b*c))', scope1).toTeX()
    const top2TeX = nerdamer('((a-d)-sqrt((a-d)^2+4*b*c))', scope1).toTeX()

    if (c === 0) {
      if (a !== d) {
        const x = d-a
        const bTeX = `${a-d}`
        const topTeX = nerdamer('r/x', { ...scope, x }).toTeX()
        const fracStr = Math.abs(x) == 1 ? '1' : `\\frac{1}{${Math.abs(x)}}`

        if (a < d) {
          str1 = `
            $$ 
              \\mathbf{x}_1 = \\begin{pmatrix} b \\\\ d - a \\end{pmatrix} =
              \\begin{pmatrix} ${b} \\\\ ${x} \\end{pmatrix} 
            $$

            $$
              \\mathbf{x}_1 = ${x < 0 ? '-' : ''} ${fracStr} \\cdot \\begin{pmatrix} ${b} \\\\ ${x} \\end{pmatrix}
                  = \\begin{pmatrix} ${topTeX} \\\\ 1 \\end{pmatrix}
            $$
          `

          const frac = nerdamer('1/((p-s)-sqrt((p-s)^2+4*q*r))', scope).toTeX()
          str2 = `
            $$ 
              \\begin{aligned}
                \\mathbf{x}_2 &= \\begin{pmatrix} a - d - \\sqrt{(a-d)^2 + 4bc} \\\\ 2 c \\end{pmatrix} \\\\[5pt]
                  &= \\begin{pmatrix} ${bTeX} - \\sqrt{(${bTeX})^2 + 4(${c})(${b})} \\\\ 2(${c}) \\end{pmatrix} = \\begin{pmatrix} ${top2TeX} \\\\ ${2*c} \\end{pmatrix} \\\\
                \\mathbf{x}_2 &= ${frac} \\cdot \\begin{pmatrix} ${top2TeX} \\\\ 0 \\end{pmatrix} = \\begin{pmatrix} 1 \\\\ 0 \\end{pmatrix}
              \\end{aligned}
            $$
          `
        } else {
          const frac = nerdamer('1/((p-s)+sqrt((p-s)^2+4*q*r))', scope).toTeX()
          str1 = `
            $$
              \\begin{aligned}
                \\mathbf{x}_1 &= \\begin{pmatrix} a - d + \\sqrt{(a-d)^2 + 4bc} \\\\ 2 c \\end{pmatrix} \\\\[5pt]
                    &= \\begin{pmatrix} ${bTeX} + \\sqrt{(${bTeX})^2 + 4(${c})(${b})} \\\\ 2(${c}) \\end{pmatrix} = \\begin{pmatrix} ${top1TeX} \\\\ ${2*c} \\end{pmatrix} \\\\
                \\mathbf{x}_1 & = ${frac} \\cdot \\begin{pmatrix} ${top1TeX} \\\\ 0 \\end{pmatrix} = \\begin{pmatrix} 1 \\\\ 0 \\end{pmatrix}
              \\end{aligned}
            $$
          `
          str2 =`
            $$
              \\begin{align}
                \\mathbf{x}_2 &= \\begin{pmatrix} b \\\\ d - a \\end{pmatrix} = \\begin{pmatrix} ${b} \\\\ ${x} \\end{pmatrix} \\\\
                \\mathbf{x}_2 & = ${x < 0 ? '-' : ''} ${fracStr} \\cdot \\begin{pmatrix} ${b} \\\\ ${x} \\end{pmatrix} = \\begin{pmatrix} ${topTeX} \\\\ 1 \\end{pmatrix}
              \\end{align}
            $$
          `
        }
      } else if (a === d) {
        const fracStr = Math.abs(b) == 1 ? '1' : `\\frac{1}{${Math.abs(b)}}`
        str1 = `
          $$ \\mathbf{x}_1 = \\begin{pmatrix} b \\\\ d - a \\end{pmatrix} =
             \\begin{pmatrix} ${b} \\\\ 0 \\end{pmatrix} $$
          $$
            \\begin{align}
              \\mathbf{x}_1 & = ${b < 0 ? '-' : ''} ${fracStr} \\cdot \\begin{pmatrix}${b} \\\\ 0 \\end{pmatrix} = \\begin{pmatrix}1\\\\ 0\\end{pmatrix}
            \\end{align}
          $$
        `
      }
    } else if (c !== 0) {
      var x1TeX = nerdamer('((p-s)+sqrt((p-s)^2+4*q*r))/(2q)', scope).toTeX()
      var x2TeX = nerdamer('((p-s)-sqrt((p-s)^2+4*q*r))/(2q)', scope).toTeX()

      // const b = a-d
      // const bTeX = `${a}${d<0?'+':'-'}${Math.abs(d)}`
      const bTeX = `${a-d}`
      // var sqrtTeX = nerdamer('sqrt(b^2+4*q*r)', {...scope, b}).toTeX()
      const fracScalar = `${c<0?'-':''}\\frac{1}{${Math.abs(2*c)}}`
      str1 = `
        $$
          \\begin{aligned}
            \\mathbf{x}_1 &= \\begin{pmatrix} a - d + \\sqrt{(a-d)^2 + 4bc} \\\\ 2 c \\end{pmatrix} \\\\
                &= \\begin{pmatrix} ${bTeX} + \\sqrt{(${bTeX})^2 + 4(${c})(${b})} \\\\ 2(${c}) \\end{pmatrix} = \\begin{pmatrix} ${top1TeX} \\\\ ${2*c} \\end{pmatrix} \\\\
            \\mathbf{x}_1 & = ${fracScalar} \\cdot \\begin{pmatrix}${top1TeX} \\\\ ${2*c}\\end{pmatrix} = \\begin{pmatrix} ${x1TeX} \\\\ 1 \\end{pmatrix}
          \\end{aligned}
        $$
      `
      str2 = `
        $$
          \\begin{aligned}
            \\mathbf{x}_2 &= \\begin{pmatrix} a - d - \\sqrt{(a-d)^2 + 4bc} \\\\ 2 c \\end{pmatrix} \\\\
                &= \\begin{pmatrix} ${bTeX} - \\sqrt{(${bTeX})^2 + 4 (${c}) (${b})} \\\\ 2(${c}) \\end{pmatrix} = \\begin{pmatrix} ${top2TeX} \\\\ ${2*c} \\end{pmatrix} \\\\
            \\mathbf{x}_2 & = ${fracScalar} \\cdot \\begin{pmatrix}${top2TeX} \\\\ ${2*c}\\end{pmatrix} = \\begin{pmatrix} ${x2TeX} \\\\ 1 \\end{pmatrix}
          \\end{aligned}
        $$
      `
    }

    if (c === 0 && b === 0 && a === d) {
      str1 = ''
      str2 = ''
    }
    setWorkstr1(str1)
    setWorkstr2(str2)

    setMatrixTex(`$$ A = \\begin{bmatrix} ${a} & ${b} \\\\ ${c} & ${d} \\end{bmatrix} $$`)
  }, [a, c, b, d])

  return (
    <div align="center" style={{margin: '1.5em 0'}}>
      <div className="grid">
        <div className="grid-row">
          <div className="grid-label">Work</div>
          <div className="grid-content">
            <div children={workstr1} />

            {workstr2 && <hr className={styles.hr}/>}

            <div children={workstr2} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default EigenWork