import * as React from "react"
import nerdamer from 'nerdamer'

import * as styles from './eigen-tool.module.css'

function eigenvectorEquation(a, c, b, d) {
  var str = ''

  const x = c
  if (x !== 0) {
    if (x < 0) {
      str += '-'
    }
    if (Math.abs(x) !== 1) {
      str += Math.abs(x)
    }
    str += 'x^2'
  }

  const y = -(a-d)
  if (y !== 0) {
    if (c && y > 0) {
      str += '+'
    } else if (y < 0) {
      str += '-'
    }
    if (Math.abs(y) !== 1) {
      str += Math.abs(y)
    }
    str += 'x'
  }

  const z = -b
  if (z !== 0) {
    if (z > 0) {
      str += '+'
    }
    str += z
  }

  return str
}

const EigenVector = props => {
  const { a, c, b, d, lambdas } = props
  const [eqnstr, setEqnStr] = React.useState(`$$ ${c}x^2 - ${a - d}x - ${b} = 0 $$`)
  const [vecTex, setVecTex] = React.useState('')

  function generateTeX() {
    let scope = { p: a, q: c, r: b, s: d }
    let vec1 = null
    let vec2 = null

    if (c === 0) {
      vec1 = [1, 0]

      if (a !== d) {
        const xtex = nerdamer('r/(s-p)', scope).toTeX()
        vec2 = [xtex, 1]

        // swap vectors to match with eigenvalues
        if (a < d) {
          vec1 = [xtex, 1]
          vec2 = [1, 0]
        }
      }
    } else if (c !== 0) {
      const x1tex = nerdamer('((p-s)+sqrt((p-s)^2+4*q*r))/(2q)', scope).toTeX()
      const x2tex = nerdamer('((p-s)-sqrt((p-s)^2+4*q*r))/(2q)', scope).toTeX()

      vec1 = [x1tex, 1]
      vec2 = [x2tex, 1]
    }

    if (c === 0 && b === 0 && a === d) {
      setVecTex(<div style={{margin:'1em 0 0'}}>Every nonzero vector.</div>)
    } else {
      // var vecTexStr = ''
      if (vec1) {
        // vecTexStr += `$$ \\lambda_1 = ${lambdas[0]} $$\n`
        setVecTex(`$$ \\mathbf{x}_1 = \\begin{pmatrix} ${vec1[0]} \\\\ ${vec1[1]} \\end{pmatrix} $$`)
      }
      if (vec2 && JSON.stringify(vec1) !== JSON.stringify(vec2)) {
        // vecTexStr += `$$ \\lambda_2 = ${lambdas[1]} $$\n`
        // vecTexStr += `$$ \\mathbf{v}_{\\lambda_2} = \\left( ${vec2[0]}, ${vec2[1]} \\right) $$`
        setVecTex(`\\begin{align}
          \\mathbf{x}_1 & = \\begin{pmatrix} ${vec1[0]} \\\\ ${vec1[1]} \\end{pmatrix} \\\\[1em]
          \\mathbf{x}_2 & = \\begin{pmatrix} ${vec2[0]} \\\\ ${vec2[1]} \\end{pmatrix}
        \\end{align}`)
      }
      // setVecTex('$$ ' + vecTexStr + ' $$')
    }

    if (a === d && c === 0) {
      setEqnStr('')
    } else {
      setEqnStr(`$$` + eigenvectorEquation(a, c, b, d) + ' = 0 $$')
    }
  }

  React.useEffect(generateTeX, [a,c,b,d,lambdas])

  return (
    <div className="grid">
      {/*<div className={styles.eqn} children={eqnstr} />*/}
      <div class="grid-row">
        <div class="grid-label">Eigenvectors</div>
        <div class="grid-content">
          <div
            className={styles.vectors}
            align="center"
            children={vecTex}
          />
        </div>
      </div>
    </div>
  )
}

export default EigenVector