import React from "react"

import * as styles from "../styles/matrix-input.module.css"

const MatrixInput = props => {
  const [a, seta] = React.useState(props.a || 3)
  const [b, setb] = React.useState(props.b || 6)
  const [c, setc] = React.useState(props.c || 1)
  const [d, setd] = React.useState(props.d || 4)

  React.useEffect(() => {
    if (props.onChange) props.onChange([a, c, b, d])
  }, [a, c, b, d])

  return (
    <div className={styles.MatrixInput} style={props.style}>
      <div className={styles.label}>$A=$</div>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td>
              <input
                type="number"
                value={a}
                onChange={e => seta(+e.target.value)}
              />
            </td>
            <td>
              <input
                type="number"
                value={b}
                onChange={e => setb(+e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>
              <input
                type="number"
                value={c}
                onChange={e => setc(+e.target.value)}
              />
            </td>
            <td>
              <input
                type="number"
                value={d}
                onChange={e => setd(+e.target.value)}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default MatrixInput
